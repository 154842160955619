import '@/src/i18n'

import Rails from '@rails/ujs'

import '@/src/polyfill'
import '@/src/landing'

import 'bootstrap'

if (!window.Rails) Rails.start() // https://github.com/ElMassimo/vite_ruby/issues/246#issuecomment-1235743299
