import SmoothScroll from 'smooth-scroll'
import { initVue2 } from './js-utils'
import BusinessSubscriptionModal from './vue-components/business-subscription-modal.vue'

declare global {
  interface Window {
    smoothScroll: any;
    businessSubscriptionCalculatorModal?: any;
  }
}

window.smoothScroll = new SmoothScroll('a[href*="#"]')

document.addEventListener('DOMContentLoaded', (): void => {
  const calculatorEl = document.querySelector('[data-action="business-calculator"]')
  if (calculatorEl instanceof HTMLElement) {
    let businessSubscriptionModalEl = null as HTMLElement | null
    calculatorEl.addEventListener('click', function (e) {
      e.preventDefault()
      if (businessSubscriptionModalEl == null) {
        businessSubscriptionModalEl = document.createElement('div')
        document.body.appendChild(businessSubscriptionModalEl)

        window.businessSubscriptionCalculatorModal = initVue2(BusinessSubscriptionModal, businessSubscriptionModalEl, {
          calculatorMode: true,
          subscriptionDetailsUrl: calculatorEl.dataset.subscriptionDetailsUrl,
        })
      }
      window.businessSubscriptionCalculatorModal.showModal()
    })
  }
})
